import * as React from "react"

// styles
import "../styles/index.css"

// logo
import logo from "../images/logo.svg"

// markup
const IndexPage = () => {
  return (
    <main>
        <img src={logo} alt="Royal Air Force - 6 Cyber Reserve Squadron Logo" class="img" />
    </main>
  )
}

export default IndexPage
